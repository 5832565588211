import * as React from "react";

import { graphql } from "gatsby";
import Layout from "../components/Layout";
import TableDataRenderer, { TableDataProps } from "../components/TableData";

const TableData: React.FC<TableDataProps> = ({ data }) => (
  <Layout>
    <TableDataRenderer data={data} />
  </Layout>
);

export const query = graphql`
  query TableData($key: FacetInput!) {
    facetBy(key: $key) {
      field
      fieldValue
      countries
      events
      views
      platforms
      tags
      vendors
      uniques
      last_updated
    }
  }
`;

export default TableData;
